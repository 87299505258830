<template>
  <div class="certification-container">
    <div class="certification-main">
      <p class="certification-main-title">实名认证之后即可购买数字藏品</p>
      <van-form @submit="onSubmit">
        <p class="cer-phone-container">手机号码：{{ phoneNumHide }}</p>
        <div
          v-if="isCaptcha"
          @click="getSms"
          style="margin-left: 5%;margin-bottom: 16px;margin-top: 16px;"
          class="right-slider-black-container"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>
        <div
          v-if="!isCaptcha"
          class="cer-sms-container"
        >
          <van-field
            autocomplete="off"
            v-model="sms"
            ref="sms"
            maxlength="6"
            class="login-sms"
            type="digit"
            name="validatorSms"
            label="验证码"
            :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="disabled"
            @click="getSms"
          >
            {{ text }}
          </button>
        </div>
        <p
          v-if="smsErrorInfo"
          class="account-status-info"
          style="color: #FF0244;"
        >{{ smsErrorInfo }}</p>
        <van-field
          class="cer-username-container"
          autocomplete="off"
          v-model="username"
          name="姓名"
          label="姓名"
          :rules="[{ validator: validatorName, message: '请输入姓名' }]"
        />
        <p
          v-if="nameErrorInfo"
          class="account-status-info"
          style="color: #FF0244;"
        >{{ nameErrorInfo }}</p>
        <van-field
          class="cer-idCard-container"
          autocomplete="off"
          v-model="idCard"
          name="身份证号"
          label="身份证号"
          :rules="[{ validator:validatorIdCard, message: '请输入正确的身份证号' }]"
        />
        <p
          v-if="idCardErrorInfo"
          class="account-status-info"
          style="color: #FF0244;"
        >{{ idCardErrorInfo }}</p>
        <div
          style="margin: 2rem 1.125rem 1rem 1.375rem;"
          class="certification-tip-list"
        >
          <p class="certification-tip">说明：</p>
          <p class="certification-tip">1、手机号、姓名、身份证号必须为同一个用户。</p>
          <p class="certification-tip">2、每个账户每天最多认证3次，超过3次24小时后可重新进行认证。</p>
          <p class="certification-tip">3、仅支持中国大陆居民实名认证。</p>
        </div>
        <div style="margin: 2.125rem 1.125rem 1rem 1.375rem;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >提 交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { create } from '../lib/captcha.js'
import { captchaAppKey } from '../lib/index.js'
import Vue from 'vue';
import md5 from 'js-md5';
import Cookies from 'js-cookie'
import api from '../api/index-client'
import { Form,Field,Button } from 'vant';
const config = require('../config')

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
export default {
  data () {
    return {
      smsErrorInfo: '',
      nameErrorInfo: '',
      idCardErrorInfo: '',
      isCaptcha: false,
      phoneNum: '',
      phoneNumHide: '',
      username: '',
      idCard: '',
      time: null,
      sms: '',
      disabled: false,
      text: '获取验证码',
    }
  },
  watch: {
    sms () {
      if (this.sms.length >= 6 || !/^[0-9]*$/.test(this.sms)) {
        this.$refs.sms.validate()
      }
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getMyInfo() // 获取个人资料
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.phoneNum = result.data.data.phone
            this.phoneNumHide = String(this.phoneNum).substring(0,3) + '****' + String(this.phoneNum).substring(String(this.phoneNum).length - 4,)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    validatorPhone (val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val); s
    },
    validatorSms (val) {
      if (!/^\d{6}$/.test(val)) {
        this.smsErrorInfo = '请输入正确的短信验证码'
      } else {
        this.smsErrorInfo = ''
        return /^\d{6}$/.test(val);
      }

    },
    validatorName (val) {
      if (!val) {
        this.nameErrorInfo = '请输入姓名'
      } else {
        this.nameErrorInfo = ''
        return true
      }
    },
    validatorIdCard (val) {
      if (!this.$utils.IdentityCodeValid(val)) {
        this.idCardErrorInfo = '请输入正确的身份证号'
      } else {
        this.idCardErrorInfo = ''
        return this.$utils.IdentityCodeValid(val);
      }

    },
    getSms () {
      this.isCaptcha = true
      this.$nextTick(() => {
        this.captcha = create('login-main-right-slider',data => {
          var timestamp = new Date().getTime()
          var nonce = md5(String(timestamp))
          var signStr = 'appId=' + config.appId + '&mobile=' + this.phoneNum + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
          var sign = md5(signStr)
          api
            .post('afs/sms',{ phone: this.phoneNum,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
            .then(result => {
              if (result.data.success) {
                this.time = 60;
                this.timer();
                this.isCaptcha = false
                this.$toast({
                  message: '获取成功',
                  icon: require('../assets/icon/toast-success.png'),
                });
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
            })
        })
        this.captcha.reset()
        this.captcha.show()
      })


    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "获取验证码";
        this.disabled = false;
      }
    },
    onSubmit () {
      if (!/^\d{6}$/.test(this.sms) || !this.$utils.IdentityCodeValid(this.idCard) || !this.username) {

      } else {
        api
          .post('user/realAuth',{ "cartId": this.idCard,"code": this.sms,"name": this.username })
          .then(result => {
            ;
            if (result.data.success && result.data.data) {
              this.$toast({
                message: '操作成功',
                icon: require('../assets/icon/toast-success.png'),
              });
              this.$router.replace('/myDetail')
            } else if (result.data.data == false) {
              this.$toast.fail('认证失败')
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
      }

    },
  },
}
</script>
<style lang="less">
.certification-container {
  margin-top: 3.125rem;
  height: calc(100vh - 3.125rem);
  overflow-y: auto;
  width: 100vw;
  z-index: 19;
  background: #1c1c1c;
  .account-status-info {
    width: 90%;
    margin: 0.5rem 5% 0 5%;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #f3cdb6;
    line-height: 1.1875rem;
  }
  .certification-tip {
    font-size: 0.75rem;
    font-family: lantinghei;
    font-weight: normal;
    color: #cccccc;
    line-height: 1.375rem;
    letter-spacing: 0.0625rem;
  }
  .certification-main {
    padding-top: 1.3125rem;

    .certification-main-title {
      width: 90%;
      margin-left: 5%;
      text-align: left;
      font-size: 1.375rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #cccccc;
      line-height: 1.9375rem;
      letter-spacing: 0.0625rem;
      margin-bottom: 2.0625rem;
    }

    .van-form {
      .van-cell::after {
        display: none;
      }

      .van-cell {
        background: none;
        padding: 1rem 0.8125rem;

        .van-cell__title,
        .van-cell__value {
          font-size: 0.875rem;
          font-family: lantingheiweight;
          font-weight: normal;
          color: #b3b3b3 !important;
          line-height: 1.1875rem;
        }

        .van-field__control {
          font-size: 0.875rem;
          font-family: lantingheiweight;
          font-weight: normal;
          color: #ffffff !important;
          line-height: 1.1875rem;
        }

        .van-field__error-message {
          position: absolute;
          top: 1rem;
        }
      }

      .van-button {
        height: 2.875rem;
        position: relative;
        background-image: url('../assets/icon/confirm-button.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: 100% auto;
        background-color: transparent;
        font-size: 1rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #ffffff;
        line-height: 1.375rem;
        border-radius: 0;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .cer-phone-container {
    width: 90%;
    margin-left: 5%;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #b3b3b3;
    line-height: 1.1875rem;
  }

  .cer-sms-container {
    .van-cell__value {
      input {
        width: 6.25rem;
      }
    }
  }

  .cer-idCard-container,
  .cer-username-container,
  .cer-sms-container {
    width: 90%;
    margin-left: 5%;
    height: 3.25rem;
    background: #313131 !important;
    border-radius: 0.25rem;
    margin-top: 1rem;
    position: relative;

    button {
      position: absolute;
      bottom: 1rem;
      right: 0.625rem;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875rem;
      font-family: lantinghei;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.1875rem;
    }
  }
}
</style>